import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Breadcrumbs from "../../components/pageProps/Breadcrumbs";
import { resetCart } from "../../redux/orebiSlice";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { toast } from "react-toastify";

const Payment = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();


  const [clientName, setclientName] = useState("");
  const [clientSurname, setclientSurname] = useState("");
  const [clientCountry, setclientCountry] = useState("");
  const [email, setEmail] = useState("");
  const [clientLocation, setclientLocation] = useState("");
  const [clientPhone, setclientPhone] = useState("");
  const [clientExtra, setclientExtra] = useState("");

  // ========== Error Messages Start here ============
  const [errClientName, setErrClientName] = useState("");
  const [errClientSurname, setErrClientSurname] = useState("");
  const [errEmail, setErrEmail] = useState("");
  const [errClientLocation, setErrClientLocation] = useState("");
  const [errClientPhone, setErrClientPhone] = useState("");
  const [errClientExtra, setErrClientExtra] = useState("");
  const [errClientCountry, setErrClientCountry] = useState("");
  // ========== Error Messages End here ==============

  const finalProducts = useSelector((state) => state.orebiReducer.products);

  const handleName = (e) => {
    setclientName(e.target.value);
    setErrClientName("");
  };
  const handleSurname = (e) => {
    setclientSurname(e.target.value);
    setErrClientSurname("");
  };
  const handleCountry = (e) => {
    setclientCountry(e.target.value);
    setErrClientCountry("");
  };
  const handleLocation = (e) => {
    setclientLocation(e.target.value);
    setErrClientLocation("");
  };
  const handlePhone = (e) => {
    setclientPhone(e.target.value);
    setErrClientPhone("");
  };
  const handleExtra = (e) => {
    setclientExtra(e.target.value);
    setErrClientExtra("");
  };
  const handleEmail = (e) => {
    setEmail(e.target.value);
    setErrEmail("");
  };

  // ================= Email Validation start here =============
  const EmailValidation = (email) => {
    return String(email)
      .toLowerCase()
      .match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i);
  };
  // ================= Email Validation End here ===============

  const handlePost = (e) => {
    let finalSend = '';
    finalProducts.forEach(item => {
      finalSend += item.quantity + " ədəd " + item.name + ", ";
    });
    
    let inputs = {name: clientName, surname: clientSurname, country: clientCountry, location: clientLocation, phone: clientPhone, email: email, extra: clientExtra, products: finalSend}
    e.preventDefault();
    if (!clientName) {
      setErrClientName("Adınızı yazın");
    }
    if (!clientSurname) {
      setErrClientSurname("Soyadınızı yazın");
    }
    if (!clientPhone) {
      setErrClientPhone("Telefon nömrənizi yazın");
    }
    if (!clientCountry) {
      setErrClientCountry("Ölkəni qeyd edin");
    }
    if (!clientLocation) {
      setErrClientLocation("Ünvanı qeyd edin");
    }
    if (!email) {
      setErrEmail("Emailinizi daxil edin");
    } else {
      if (!EmailValidation(email)) {
        setErrEmail("Enter a Valid Email");
      }
    }
    if (clientName && email && EmailValidation(email) && clientPhone && clientSurname && clientCountry && clientLocation) {
      axios.post("https://www.safqida.az/safqida/orders.php/", inputs)
      .then(function (response) {
        toast.success("Sifarişiniz qeydə alındı");
        navigate("/mehsullar");
        setclientName("")
        setclientSurname("")
        setclientCountry("")
        setclientLocation("")
        setclientPhone("")
        setEmail("")
        setclientExtra("")
        dispatch(resetCart())
        // console.log(inputs);
      });
    }
  };
  
  return (
    <div className="max-w-container mx-auto px-4">
      <Breadcrumbs title="Sifarişin tamamlanması" />
      <div className="pb-10">
        <p>Sifarişin tamamlanması</p>
        <form className="pb-20">
          <h1 className="font-titleFont font-semibold text-3xl">
            Formu doldurun
          </h1>
          <div className="max-w-[600px] h-auto py-6 flex flex-col gap-6">
            <div>
              <p className="text-base font-titleFont font-semibold px-2">
                Ad
              </p>
              <input
                onChange={handleName}
                value={clientName}
                className="w-full py-1 border-b-2 px-2 text-base font-medium placeholder:font-normal placeholder:text-sm outline-none focus-within:border-primeColor"
                type="text"
                placeholder="Adınızı daxil edin"
              />
              {errClientName && (
                <p className="text-red-500 text-sm font-titleFont font-semibold mt-1 px-2 flex items-center gap-1">
                  <span className="text-sm italic font-bold">!</span>
                  {errClientName}
                </p>
              )}
            </div>
            <div>
              <p className="text-base font-titleFont font-semibold px-2">
                Soyad
              </p>
              <input
                onChange={handleSurname}
                value={clientSurname}
                className="w-full py-1 border-b-2 px-2 text-base font-medium placeholder:font-normal placeholder:text-sm outline-none focus-within:border-primeColor"
                type="text"
                placeholder="Soyadınızı daxil edin"
              />
              {errClientSurname && (
                <p className="text-red-500 text-sm font-titleFont font-semibold mt-1 px-2 flex items-center gap-1">
                  <span className="text-sm italic font-bold">!</span>
                  {errClientSurname}
                </p>
              )}
            </div>
            <div>
              <p className="text-base font-titleFont font-semibold px-2">
                Email
              </p>
              <input
                onChange={handleEmail}
                value={email}
                className="w-full py-1 border-b-2 px-2 text-base font-medium placeholder:font-normal placeholder:text-sm outline-none focus-within:border-primeColor"
                type="email"
                placeholder="Emailinizi daxil edin"
              />
              {errEmail && (
                <p className="text-red-500 text-sm font-titleFont font-semibold mt-1 px-2 flex items-center gap-1">
                  <span className="text-sm italic font-bold">!</span>
                  {errEmail}
                </p>
              )}
            </div>
            <div>
              <p className="text-base font-titleFont font-semibold px-2">
                Telefon
              </p>
              <input
                onChange={handlePhone}
                value={clientPhone}
                className="w-full py-1 border-b-2 px-2 text-base font-medium placeholder:font-normal placeholder:text-sm outline-none focus-within:border-primeColor"
                type="text"
                placeholder="Telefonunuzu daxil edin"
              />
              {errClientPhone && (
                <p className="text-red-500 text-sm font-titleFont font-semibold mt-1 px-2 flex items-center gap-1">
                  <span className="text-sm italic font-bold">!</span>
                  {errClientPhone}
                </p>
              )}
            </div>
            <div>
              <p className="text-base font-titleFont font-semibold px-2">
                Ölkə
              </p>
              <input
                onChange={handleCountry}
                value={clientCountry}
                className="w-full py-1 border-b-2 px-2 text-base font-medium placeholder:font-normal placeholder:text-sm outline-none focus-within:border-primeColor"
                type="text"
                placeholder="Ölkəni daxil edin"
              />
              {errClientCountry && (
                <p className="text-red-500 text-sm font-titleFont font-semibold mt-1 px-2 flex items-center gap-1">
                  <span className="text-sm italic font-bold">!</span>
                  {errClientCountry}
                </p>
              )}
            </div>
            <div>
              <p className="text-base font-titleFont font-semibold px-2">
                Ünvan
              </p>
              <input
                onChange={handleLocation}
                value={clientLocation}
                className="w-full py-1 border-b-2 px-2 text-base font-medium placeholder:font-normal placeholder:text-sm outline-none focus-within:border-primeColor"
                type="text"
                placeholder="Ünvanınızı daxil edin"
              />
              {errClientLocation && (
                <p className="text-red-500 text-sm font-titleFont font-semibold mt-1 px-2 flex items-center gap-1">
                  <span className="text-sm italic font-bold">!</span>
                  {errClientLocation}
                </p>
              )}
            </div>
            <div>
              <p className="text-base font-titleFont font-semibold px-2">
                Sifariş üçün əlavə qeydlər (istəyə bağlı)
              </p>
              <input
                onChange={handleExtra}
                value={clientExtra}
                className="w-full py-1 border-b-2 px-2 text-base font-medium placeholder:font-normal placeholder:text-sm outline-none focus-within:border-primeColor"
                type="text"
                placeholder="Sifarişlə bağlı əlavə qeydləriniz varsa, bu hissədə qeyd edin"
              />
              {errClientExtra && (
                <p className="text-red-500 text-sm font-titleFont font-semibold mt-1 px-2 flex items-center gap-1">
                  <span className="text-sm italic font-bold">!</span>
                  {errClientExtra}
                </p>
              )}
            </div>
            <button
              onClick={handlePost}
              className="w-44 bg-primeColor text-gray-200 h-10 font-titleFont text-base tracking-wide font-semibold hover:bg-black hover:text-white duration-200"
            >
              Sifarişi tamamla
            </button>
          </div>
        </form>
        <Link to="/">
          <button className="w-52 h-10 bg-primeColor text-white text-lg mt-4 hover:bg-black duration-300">
            Ana səhifəyə qayıt
          </button>
        </Link>
      </div>
    </div>
  );
};

export default Payment;
