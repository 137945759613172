import React, { useState } from "react";
import Slider from "react-slick";
import {
  honey,
  tea,
  fin
} from "../../assets/images";
import Image from "../designLayouts/Image";
import './banner.css';
import { Link } from "react-router-dom";

const CustomSlide = ({ Subtext, imgSrc, text, buttonLink, buttonText }) => (
  <div className="slide-container">
    <div className="slide-content">
      <h1 className="slide-title">{text}</h1>
      <p className="slide-subtext">{Subtext}</p>
      <Link to={buttonLink}>
        <button className="slide-button">{buttonText}</button>
      </Link>
    </div>
    <div className="slide-image">
      <Image className="unique-image" imgSrc={imgSrc} />
    </div>
  </div>
);

const Banner = () => {
  const [dotActive, setDotActive] = useState(0);
  
  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    arrows: false,
    beforeChange: (prev, next) => setDotActive(next),
    appendDots: dots => (
      <div className="dots-container">
        <ul>{dots}</ul>
      </div>
    ),
    customPaging: i => (
      <div className={i === dotActive ? "dot active" : "dot"}>
        0{i + 1}
      </div>
    ),
    responsive: [
      {
        breakpoint: 576,
        settings: {
          dots: true,
          appendDots: dots => (
            <div className="dots-container-mobile">
              <ul>{dots}</ul>
            </div>
          ),
          customPaging: i => (
            <div className={i === dotActive ? "dot active" : "dot"}>
              0{i + 1}
            </div>
          ),
        },
      },
    ],
  };

  const slides = [
    {
      imgSrc: honey,
      text: "Sağlam həyat tərziniz",
      Subtext: "Münasib qiymətlərlə sağlam qidalanma üçün məhsullarımızı kəşf edin",
      buttonLink: "/offer",
      buttonText: "İndi al",
    },
    {
      imgSrc: tea,
      text: "Orqanik məhsullar",
      Subtext: "Bütün növ orqanik məhsulları almaq imkanı",
      buttonLink: "/mehsullar",
      buttonText: "Haqqımızda",
    },
    {
      imgSrc: fin,
      text: "SafQida - Sağlam Həyat",
      Subtext: "Sağlam qidalanın, bütün növ təbii məhsulları almaq imkanı",
      buttonLink: "/elaqe",
      buttonText: "Bizimlə əlaqə",
    },
  ];

  return (
    <div className="banner-container">
      <Slider {...settings}>
        {slides.map((slide, index) => (
          <CustomSlide key={index} {...slide} />
        ))}
      </Slider>
    </div>
  );
};

export default Banner;
