import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import Product from "../../components/home/Products/Product";
import { useSelector } from "react-redux";
// import { paginationItems } from "../../../constants";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import HeaderBottom from "../../components/home/Header/HeaderBottom";
import loadingGif from './loading.gif'
import DashboardHeader from "../../components/home/DashboardHeader/DashboardHeader";
 
// const items = paginationItems;


function Items({ currentItems, selectedBrands, selectedCategories }) {
  // Filter items based on selected brands and categories
  const filteredItems = currentItems.filter((item) => {
    const isBrandSelected = 
      selectedBrands.length === 0 ||
      selectedBrands.some((brand) => brand.title === item.brand);

    const isCategorySelected =
      selectedCategories.length === 0 ||
      selectedCategories.some((category) => category.title === item.category);

    return isBrandSelected && isCategorySelected;
  });

  return (
    <>
      {filteredItems.map((item) => (
        <div key={item.id} className="w-full">
          <Product
            id={item.id}
            image={item.image}
            name={item.name}
            price={item.price}
            country={item.country}
            exist={item.exist}
            des={item.des}
            pdf={item.pdf}
            ficheTech={item.ficheTech}
          />
        </div>
      ))}
    </>
  );
}

const Pagination = () => {
  const { category } = useParams();
  const [itemOffset, setItemOffset] = useState(0);
  const [itemStart, setItemStart] = useState(1);
  const [items, setItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true); // loading durumu eklendi

  const endOffset = itemOffset + items.length;
  const currentItems = items.slice(itemOffset, endOffset);
  const selectedBrands = useSelector(
    (state) => state.orebiReducer.checkedBrands
  );
  const selectedCategories = useSelector(
    (state) => state.orebiReducer.checkedCategorys
  );
  const pageCount = Math.ceil(items.length / items.length);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * items.length) % items.length;
    const newStart = newOffset + 1; // Start index'i ayarla

    setItemOffset(newOffset);
    setItemStart(newStart);
  };

  useEffect(() => {
    getProducts();
  }, [category]);

  const getProducts = async () => {
    try {
      axios.get('https://www.safqida.az/safqida/dashboard.php/', {
        params: {
            category: category
        }
    })
    .then(response => {
        setItems(response.data);
        isLoading(false)
    })
    .catch(error => {
        console.error(error);
    });
      } finally {
      setIsLoading(false); // fetch işlemi tamamlandığında loading durumu false olacak
    }
  };




  const deleteUser = (id) => {
    axios
      .delete(`https://www.safqida.az/safqida/allDashboard.php/${id}/delete`)
      .then(function (response) {
        getProducts();
      });
  };


  


  return (
    <div>
    {isLoading || items.length === 0 ? ( // isLoading true ise veya items henüz gelmemişse loading göster
     <img style={{margin:'0 auto'}} width={40} height={40} src={loadingGif} alt="Loading"></img>
    ) : (
      <>
      <DashboardHeader/>
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <div className="container"></div>
      </nav>
      <div className="container" style={{ paddingTop: 50 }}>
        <div>
          <table> 
            <thead>
              <tr>
              <th>Actions</th>
                <th>ID</th>
                <th>Ad</th>
                <th>Qiymət</th>
                <th>Mövcuddur</th>
                <th>Kateqoriya</th>
                <th>Ölkə</th>
                <th>Şəkil</th>
              </tr>
            </thead>
            <tbody>
              {items.map((user, key) => (
                <tr key={key}>
                  <td>
                    <Link
                      to={`/dashboard/${user.id}/edit`}
                      style={{ marginRight: "10px" }}
                    >
                      Yenilə
                    </Link>
                    <button onClick={() => deleteUser(user.id)}>Sil</button>
                  </td>
                  <td>{user.id}</td>
                  <td>{user.name}</td>
                  <td>{user.price}</td>
                  <td>{user.exist}</td>
                  <td>{user.category}</td>
                  <td>{user.country}</td>
                  <td>Şəkil url</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      </>
    )}
  </div>
  );
};

export default Pagination;
