import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import Product from "../../home/Products/Product";
import { useSelector } from "react-redux";
// import { paginationItems } from "../../../constants";
import axios from "axios";

// const items = paginationItems;


function Items({ currentItems, selectedBrands, selectedCategories }) {
  // Filter items based on selected brands and categories
  const filteredItems = currentItems.filter((item) => {
    const isBrandSelected = 
      selectedBrands.length === 0 ||
      selectedBrands.some((brand) => brand.title === item.brand);

    const isCategorySelected =
      selectedCategories.length === 0 ||
      selectedCategories.some((category) => category.title === item.category);

    return isBrandSelected && isCategorySelected;
  });

  return (
    <>
      {filteredItems.map((item) => (
        <div key={item.id} className="w-full">
          <Product
            id={item.id}
            image={item.image}
            name={item.name}
            price={item.price}
            country={item.country}
            exist={item.exist}
            des={item.des}
            pdf={item.pdf}
            ficheTech={item.ficheTech}
          />
        </div>
      ))}
    </>
  );
}

const Pagination = () => {
  const [itemOffset, setItemOffset] = useState(0);
  const [itemStart, setItemStart] = useState(1);
  const [items, setItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true); // loading durumu eklendi

  const endOffset = itemOffset + items.length;
  const currentItems = items.slice(itemOffset, endOffset);
  const selectedBrands = useSelector(
    (state) => state.orebiReducer.checkedBrands
  );
  const selectedCategories = useSelector(
    (state) => state.orebiReducer.checkedCategorys
  );
  const pageCount = Math.ceil(items.length / items.length);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * items.length) % items.length;
    const newStart = newOffset + 1; // Start index'i ayarla

    setItemOffset(newOffset);
    setItemStart(newStart);
  };

  useEffect(() => {
    getProducts();
  }, []);

  const getProducts = async () => {
    try {
      const response = await axios.get(
        "https://www.safqida.az/safqida/dashboard.php/"
      );
      setItems(response.data);
    } catch (error) {
      console.error("Error fetching users:", error);
    } finally {
      setIsLoading(false); // fetch işlemi tamamlandığında loading durumu false olacak
    }
  };

  return (
    <div>
      {isLoading ? ( // loading durumunu kontrol et ve ekrana "loading" yazdır
       <img style={{margin:'0 auto'}} width={40} height={40} src="./loading.gif"></img>
      ) : (
        <>
          <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-10 mdl:gap-4 lg:gap-10">
            <Items
              currentItems={currentItems}
              selectedBrands={selectedBrands}
              selectedCategories={selectedCategories}
            />
          </div>
          {/* <div className="flex flex-col mdl:flex-row justify-center mdl:justify-between items-center">
            <ReactPaginate
              nextLabel=""
              onPageChange={handlePageClick}
              pageRangeDisplayed={3}
              marginPagesDisplayed={2}
              pageCount={pageCount}
              previousLabel=""
              pageLinkClassName="w-9 h-9 border-[1px] border-lightColor hover:border-gray-500 duration-300 flex justify-center items-center"
              pageClassName="mr-6"
              containerClassName="flex text-base font-semibold font-titleFont py-10"
              activeClassName="bg-black text-white"
            />

            <p className="text-base font-normal text-lightText">
              Məhsullar {itemStart}-dən {Math.min(endOffset, items.length)} -ə{" "}
            </p>
          </div> */}
        </>
      )}
    </div>
  );
};

export default Pagination;
