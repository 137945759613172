import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Breadcrumbs from "../../components/pageProps/Breadcrumbs";

const Videolar = () => {
  const location = useLocation();
  // const [prevLocation, setPrevLocation] = useState("");
  // useEffect(() => {
  //   setPrevLocation(location.state.data);
  // }, [location]);
  return (
    <div className="max-w-container mx-auto px-4">
      <Breadcrumbs title="Videolar" />
      <div className="pb-10">
        <h1 className="max-w-[600px] text-base text-lightText mb-2">
          <span className="text-primeColor font-semibold text-lg">SafQida</span>{" "}
          <iframe width="400" height="315" src="https://www.youtube.com/embed/tIoA9M_HXFw?si=3IEseA7ge0UEkoM1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
        </h1>
        <Link to="/mehsullar">
          <button className="w-52 h-10 bg-primeColor text-white hover:bg-black duration-300">
            Məhsullara keçid et
          </button>
        </Link>
      </div>
    </div>
  );
};

export default Videolar;
