import React from "react";

const ShopNow = () => {
  return (
  <a href="/mehsullar">
     <button className="bg-primeColor text-white text-lg font-bodyFont w-[185px] h-[50px] hover:bg-black duration-300 font-bold">
      İndi al
    </button>
  </a>
  );
};

export default ShopNow;
