import { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import DashboardHeader from "../../components/home/DashboardHeader/DashboardHeader";

export default function ListUser() {
  const navigate = useNavigate();

  const [inputs, setInputs] = useState([]);

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };
  function handleChangeImage(event) {
    if (event.target.name === "image") {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onloadend = () => {
          const base64String = reader.result;
          const base64WithoutHeader = base64String.replace(
            /^data:image\/[a-z]+;base64,/,
            ""
          );
          setInputs((values) => ({
            ...values,
            image: base64WithoutHeader,
          }));
        };
        reader.readAsDataURL(file);
      }
    } else {
      const name = event.target.name;
      const value = event.target.value;
      setInputs((values) => ({ ...values, [name]: value }));
    }
  }
  const handleSubmit = (event) => {
    event.preventDefault();

    axios.post("https://www.safqida.az/safqida/dashboard.php/save", inputs)
    console.log(inputs)
        navigate("/dashboard");
  };

  return (
    <>
    <DashboardHeader/>
    <div>
      <form onSubmit={handleSubmit}>
        <table cellSpacing="10">
          <tbody>
            <tr>
              <th>
                <label>Ad: </label>
              </th>
              <td>
                <input type="text" name="name" onChange={handleChange} />
              </td>
            </tr>
            <tr>
  <th>
    <label>Kateqoriya: </label>
  </th>
  <td>
    <select name="category" onChange={handleChange}>
      <option value="">Seçin</option>
      <option value="'Schär' məhsulları">'Schär' məhsulları</option>
      <option value="Arıçılıq məhsulları">Arıçılıq məhsulları</option>
      <option value="Bəkməzlər">Bəkməzlər</option>
      <option value="Bitki suları">Bitki suları</option>
      <option value="Çaylar">Çaylar</option>
      <option value="Çərəzlər">Çərəzlər</option>
      <option value="Çörəklər">Çörəklər</option>
      <option value="Digər məhsullar">Digər məhsullar</option>
      <option value="Düyülər">Düyülər</option>
      <option value="Duzlar">Duzlar</option>
      <option value="Ersağ məhsulları">Ersağ məhsulları</option>
      <option value="Ədviyyatlar">Ədviyyatlar</option>
      <option value="Əzmələr">Əzmələr</option>
      <option value="Halvalar">Halvalar</option>
      <option value="Kompot və nektarlar">Kompot və nektarlar</option>
      <option value="Meyvə quruları">Meyvə quruları</option>
      <option value="Mürəbbələr">Mürəbbələr</option>
      <option value="Organik kosmetik vasitələr">Organik kosmetik vasitələr</option>
      <option value="Paxlalılar">Paxlalılar</option>
      <option value="Qablaşdırılmış məhsullar">Qablaşdırılmış məhsullar</option>
      <option value="Qlutensiz şirniyyatlar">Qlutensiz şirniyyatlar</option>
      <option value="Quru otlar">Quru otlar</option>
      <option value="Quş məhsulları">Quş məhsulları</option>
      <option value="Sabunlar">Sabunlar</option>
      <option value="Sirkələr">Sirkələr</option>
      <option value="Siroplar">Siroplar</option>
      <option value="Şokoladlar">Şokoladlar</option>
      <option value="Şorabalar">Şorabalar</option>
      <option value="Soyuq sıxım yağlar">Soyuq sıxım yağlar</option>
      <option value="Süd və süd məhsulları">Süd və süd məhsulları</option>
      <option value="Unlar">Unlar</option>
      <option value="Urublar">Urublar</option>
      <option value="Xəmir yeməkləri">Xəmir yeməkləri</option>
      <option value="Yarpaqlar">Yarpaqlar</option>


    </select>
  </td>
</tr>
            <tr>
  <th>
    <label>Mövcuddur: </label>
  </th>
  <td>
    <select name="exist" onChange={handleChange}>
      <option value="">Seçin</option>
      <option value="yes">Yes</option>
      <option value="no">No</option>
    </select>
  </td>
</tr>

            <tr>
              <th>
                <label>Qiymət: </label>
              </th>
              <td>
                <input type="text" name="price" onChange={handleChange} />
              </td>
            </tr>
            <tr>
              <th>
                <label>Region: </label>
              </th>
              <td>
                <input type="text" name="country" onChange={handleChange} />
              </td>
            </tr>
            <tr>
              <th>
                <label>Şəkil: </label>
              </th>
              <td>
                <input type="file" name="image" onChange={handleChangeImage} />
              </td>
            </tr>
            <tr>
              <td colSpan="2" align="right">
                <button>Yadda saxla</button>
              </td>
            </tr>
          </tbody>
        </table>
      </form>
    </div></>
  );
}
