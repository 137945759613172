import axios from "axios";
import React, { useEffect, useState } from "react";
import "./dashboard.css";
import { Link, NavLink, useNavigate } from "react-router-dom";
import DashboardHeader from "../../components/home/DashboardHeader/DashboardHeader";
import { tea, fin , honey, schar, ariciliq, bekmezler, bitki, caylar, cerezler, corekler, diger, duyuler, duzlar, ersag, edviyyat, ezmeler, halvalar, meyvequru, murebbe, organik, paxlali, qablasdirilmis, qlutensiz, quruot, qusmehsul, sabunlar, sirkeler, siroplar, sokolad, soraba, soyuqsixim, sudmehsul, unlar, urublar, xemir, kompot } from "../../assets/images/index";

export default function Dashboard() {

  const categories = [
    { name: "'Schär' məhsulları", img: schar,  path: "/category/'Schär' məhsulları" },
    { name: "Arıçılıq məhsulları", img: ariciliq,  path: '/category/Arıçılıq məhsulları' },
    { name: 'Bəkməzlər', img: bekmezler,  path: '/category/Bəkməzlər' },
    { name: 'Bitki suları',img: bitki,  path: '/category/Bitki suları' },
    { name: 'Çaylar', img: caylar,  path: '/category/Çaylar' },
    { name: 'Çərəzlər', img: cerezler,  path: '/category/Çərəzlər' },
    { name: "Çörəklər", img: corekler,  path: '/category/Çörəklər' },
    { name: "Digər məhsullar", img: diger,  path: '/category/Digər məhsullar' },
    { name: 'Düyülər', img: duyuler,  path: '/category/Düyülər' },
    { name: 'Duzlar',img: duzlar,  path: '/category/Duzlar' },
    { name: 'Ersağ məhsulları', img: ersag,  path: '/category/Ersağ məhsulları' },
    { name: 'Ədviyyatlar', img: edviyyat,  path: '/category/Ədviyyatlar' },
    { name: 'Əzmələr', img: ezmeler,  path: '/category/Əzmələr' },
    { name: 'Halvalar', img: halvalar,  path: '/category/Halvalar' },
    { name: "Kompot və nektarlar", img: kompot,  path: '/category/Kompot və nektarlar' },
    { name: "Meyvə quruları", img: meyvequru,  path: '/category/Meyvə quruları' },
    { name: 'Mürəbbələr', img: murebbe,  path: '/category/Mürəbbələr' },
    { name: 'Organik kosmetik vasitələr',img: organik,  path: '/category/Organik kosmetik vasitələr' },
    { name: 'Paxlalılar', img: paxlali,  path: '/category/Paxlalılar' },
    { name: 'Qablaşdırılmış məhsullar', img: qablasdirilmis,  path: '/category/Qablaşdırılmış məhsullar' },
    { name: 'Qlutensiz şirniyyatlar', img: qlutensiz,  path: '/category/Qlutensiz şirniyyatlar' },
    { name: 'Quru otlar', img: quruot,  path: '/category/Quru otlar' },
    { name: "Quş məhsulları", img: qusmehsul,  path: '/category/Quş məhsulları' },
    { name: "Sabunlar", img: sabunlar,  path: '/category/Sabunlar' },
    { name: 'Sirkələr', img: sirkeler,  path: '/category/Sirkələr' },
    { name: 'Siroplar',img: siroplar,  path: '/category/Siroplar' },
    { name: 'Şokoladlar', img: sokolad,  path: '/category/Şokoladlar' },
    { name: 'Şorabalar', img: soraba,  path: '/category/Şorabalar' },
    { name: 'Soyuq sıxım yağlar', img: soyuqsixim,  path: '/category/Soyuq sıxım yağlar' },
    { name: "Süd və süd məhsulları", img: sudmehsul,  path: '/category/Süd və süd məhsulları' },
    { name: 'Unlar', img: unlar,  path: '/category/Unlar' },
    { name: 'Urublar',img: urublar,  path: '/category/Urublar' },
    { name: 'Xəmir yeməkləri', img: xemir,  path: '/category/Xəmir yeməkləri' },
    { name: 'Yarpaqlar', img: honey,  path: '/category/Yarpaqlar' },
  ];
  const naviget = useNavigate();
  function logoutSubmit() {
    localStorage.setItem("login", "");
    localStorage.setItem("loginStatus", "Logged out successfully!");
    naviget("/");
  }
  const user = localStorage.getItem("user");

  const [products, setProducts] = useState([]);
  useEffect(() => {
    getProducts();
  }, []);

  function getProducts() { 
    // axios
    //   .get("https://www.safqida.az/safqida/allDashboard.php/")
    //   .then(function (response) {
    //     console.log(response.data)
    //     setProducts(response.data);
    //   });
    axios.get('https://www.safqida.az/safqida/dashboard.php/', {
  })
  .then(response => {
      setProducts(response.data);
  })
  }

  const deleteUser = (id) => {
    axios
      .delete(`https://www.safqida.az/safqida/allDashboard.php/${id}/delete`)
      .then(function (response) {
        getProducts();
      });
  };
  return (
    <>
    <DashboardHeader/>
      <div className="mt-8 grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-4">
    {categories.map((category, index) => (
      <NavLink
      key={category.name}
      to={`/dashboard${category.path}`}
    >
      <div key={index} className="text-center">
        <img 
          src={category.img} 
          alt={category.name} 
          className="w-24 h-24 mx-auto rounded-full object-cover"
        />
      
    <p className="mt-2 text-gray-700">{category.name}</p>
      </div>
      </NavLink>
    ))}
  </div>
    </>
  );
}
