import { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import DashboardHeader from "../../components/home/DashboardHeader/DashboardHeader";

export default function ListUser() {
  const navigate = useNavigate();

  const [inputs, setInputs] = useState({
    name: "",
    price: "",
    exist: "",
    category: "",
    country: "",
  });

  const { id } = useParams();

  useEffect(() => {
    getProducts();
  }, []);

  function getProducts() {
    axios
      .get(`https://www.safqida.az/safqida/allDashboard.php/${id}`)
      .then(function (response) {
        setInputs(response.data);
      });
  }

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    axios
      .put(`https://www.safqida.az/safqida/dashboard.php/${id}/edit`, inputs)
      .then(function (response) {
        navigate("/dashboard");
      });
  };

  return (
    <>
    <DashboardHeader/>
    <div>
      <form onSubmit={handleSubmit}>
        <table cellSpacing="10">
          <tbody>
            <tr>
              <th>
                <label>Ad: </label>
              </th>
              <td>
                <input
                  value={inputs.name}
                  type="text"
                  name="name"
                  onChange={handleChange}
                />
              </td>
            </tr>
            <tr>
              <th>
                <label>Qiymət: </label>
              </th>
              <td>
                <input
                  value={inputs.price}
                  type="text"
                  name="price"
                  onChange={handleChange}
                />
              </td>
            </tr>
            <tr>
              <th>
                <label>Mövcuddur: </label>
              </th>
              <td>
                <input
                  value={inputs.exist}
                  type="text"
                  name="exist"
                  onChange={handleChange}
                />
              </td>
            </tr>
            <tr>
              <th>
                <label>Kateqoriya: </label>
              </th>
              <td>
                <input
                  value={inputs.category}
                  type="text"
                  name="category"
                  onChange={handleChange}
                />
              </td>
            </tr>
            <tr>
              <th>
                <label>Region: </label>
              </th>
              <td>
                <input
                  value={inputs.country}
                  type="text"
                  name="country"
                  onChange={handleChange}
                />
              </td>
            </tr>
            {/* <tr>
              <th>
                <label>image: </label>
              </th>
              <td>
                <input
                  value={inputs.image}
                  type="text"
                  name="image"
                  onChange={handleChange}
                />
              </td>
            </tr> */}
            <tr>
              <td colSpan="2" align="right">
                <button>Yadda saxla</button>
              </td>
            </tr>
          </tbody>
        </table>
      </form>
    </div></>
  );
}
