import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Breadcrumbs from "../../components/pageProps/Breadcrumbs";

const About = () => {
  const location = useLocation();
  // const [prevLocation, setPrevLocation] = useState("");
  // useEffect(() => {
  //   setPrevLocation(location.state.data);
  // }, [location]);
  return (
    <div className="max-w-container mx-auto px-4">
      <Breadcrumbs title="Haqqımızda"  />
      <div className="pb-10">
        <h1 className="max-w-[600px] text-base text-lightText mb-2">
          <span className="text-primeColor font-semibold text-lg">Saf Qida</span>{" "}
           insanların sağlam qidalanmasına xidmət etmək məqsədi ilə 2012-ci ildə yaradılmış yerli şirkətdir.

Fəaliyyətə başladığımız ilk gündən etibarən, daima müştəri məmnuniyyətini hər şeydən üstün tutmuş, qatqısız, təmiz və sağlam qidalar təqdim etməyi qarşımıza məqsəd qoymuşuq. Bu yolda qazandığımız uğurları müştərilərimizin sevgisi və sadiqliyi sayəsində dərk edir və onlar qarşısında məsuliyyətimizi anlayırıq.

Saf Qida təqdim etdiyi məhsulların testdən keçirilərək satışa çıxarılmasına zəmanət verir. Bunun sayəsində Saf Qida markası altında satılan məhsullar əminliklə, heç bir qatqı maddəsindən istifadə edilmədən hazırlanır və təbiətdən gələn ləzzəti ilə ilk  gün olan təravətini sonadək saxlayır.

Yarandığı gündən bu günə daima özünü yeniləyən, bu sahədə qabaqcıl şirkətlər arasında  iştirak etməkdən qürur duyan Saf Qida özünü davamlı olaraq inkişaf etdirir. Bugün artıq 2 mağazamız, təcrübəli mütəxəssislərimiz, keyfiyyətli məhsullarımız, professional işçi heyətimiz ilə sizlərə xidmət göstərməkdəyik.
        </h1>
        <Link to="/mehsullar">
          <button className="w-52 h-10 bg-primeColor text-white hover:bg-black duration-300">
            Məhsullara keçid et
          </button>
        </Link>
      </div>
    </div>
  );
};

export default About;
