import { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import DashboardHeader from "../../components/home/DashboardHeader/DashboardHeader";

export default function CreateOrder() {
  const navigate = useNavigate();

  const [inputs, setInputs] = useState([]);

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };
  const handleSubmit = (event) => {
    event.preventDefault();

    axios.post("https://www.safqida.az/safqida/orders.php/save", inputs)
      .then(function (response) {
        navigate("/orders");
      });
  };

  return (
   <>
   <DashboardHeader/>
    <div>
      <form onSubmit={handleSubmit}>
        <table cellSpacing="10">
          <tbody>
            <tr>
              <th>
                <label>Ad: </label>
              </th>
              <td>
                <input type="text" name="name" onChange={handleChange} />
              </td>
            </tr>
            <tr>
              <th>
                <label>Soyad: </label>
              </th>
              <td>
                <input type="text" name="surname" onChange={handleChange} />
              </td>
            </tr>
            <tr>
              <th>
                <label>Ölkə: </label>
              </th>
              <td>
                <input type="text" name="country" onChange={handleChange} />
              </td>
            </tr>
            <tr>
              <th>
                <label>Ünvan: </label>
              </th>
              <td>
                <input type="text" name="location" onChange={handleChange} />
              </td>
            </tr>
            <tr>
              <th>
                <label>Telefon: </label>
              </th>
              <td>
                <input type="text" name="phone" onChange={handleChange} />
              </td>
            </tr>
            <tr>
              <th>
                <label>Email: </label>
              </th>
              <td>
                <input type="text" name="email" onChange={handleChange} />
              </td>
            </tr>
            <tr>
              <th>
                <label>Qeyd: </label>
              </th>
              <td>
                <input type="text" name="extra" onChange={handleChange} />
              </td>
            </tr>
            <tr>
              <th>
                <label>Məhsullar: </label>
              </th>
              <td>
                <input type="text" name="products" onChange={handleChange} />
              </td>
            </tr>
            <tr>
              <td colSpan="2" align="right">
                <button>Yadda saxla</button>
              </td>
            </tr>
          </tbody>
        </table>
      </form>
    </div></>
  );
}
