import React, { useEffect, useState } from "react";
import Heading from "../Products/Heading";
import Product from "../Products/Product";
import axios from "axios";

const BestSellers = () => {

  const [products, setProducts] = useState([]);

  useEffect(() => {
      getProducts();
  }, []);

  async function getProducts() {
      try {
          // const response = await axios.get('https://www.safqida.az/safqida/dashboard.php/');
          axios.get('https://www.safqida.az/safqida/dashboard.php/', {
            params: {
                category: "Çaylar"
            }
        })
        .then(response => {
            setProducts(response.data);
        })
      } catch (error) {
          console.error('Error fetching users:', error);
      }
  }

 

  return (
    <div className="w-full pb-20">
      <Heading heading="Ən çox satılanlar" />
      <div className="w-full grid grid-cols-1 md:grid-cols-2 lgl:grid-cols-3 xl:grid-cols-4 gap-10">
        {/* <Product
          id="1011"
          img={bestSellerOne}
          name="Flower Base"
          price="35.00"
          color="Blank and White"
          badge={true}
          des="Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis."
        />
        <Product
          id="1012"
          img={bestSellerTwo}
          name="New Backpack"
          price="180.00"
          color="Gray"
          badge={false}
          des="Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis."
        />
        <Product
          id="1013"
          img={bestSellerThree}
          name="Household materials"
          price="25.00"
          color="Mixed"
          badge={true}
          des="Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis."
        />
        <Product
          id="1014"
          img={bestSellerFour}
          name="Travel Bag"
          price="220.00"
          color="Black"
          badge={false}
          des="Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis."
        /> */}
         {products.slice(0, 4).map(item => (
  <div className="px-2" key={item.id}>
    <Product
      id={item.id}
      image={item.image}
      name={item.name}
      price={item.price}
      color={item.country}
      badge={true}
      des={item.category}
    />
  </div>
))}
      </div>
    </div>
  );
};

export default BestSellers;
