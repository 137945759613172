import "./style.css";

export const navBarList = [
  {
    id: 1001,
    title: "Əsas",
    link: "/",
  },
  {
    id: 1002,
    title: "Məhsullar",
    link: "/mehsullar",
  },
  {
    id: 1003,
    title: "Haqqımızda",
    link: "/about",
  },
  {
    id: 1004,
    title: "Əlaqə",
    link: "elaqe",
  },
  {
    id: 1005,
    title: "Videolar",
    link: "/videolar",
  },
];

